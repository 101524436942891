<template lang="pug">
LiveDemoEn
</template>
<script>
  import LiveDemoEn from '@/components/Login/LiveDemoEn.vue';

  export default {
    components: { LiveDemoEn },
  };
</script>
